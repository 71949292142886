import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-purple-800 text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">Pereira & Asociados | Abogados</h3>
            <p className="mb-2">Especialistas en Derecho a la Maternidad</p>
            <p>Av. La Dehesa 181, Of.316 - Lo Barnechea</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Enlaces rápidos</h3>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-purple-300 transition">Inicio</Link></li>
              <li><Link to="/areas-practica" className="hover:text-purple-300 transition">Áreas de Práctica</Link></li>
              <li><Link to="/contacto" className="hover:text-purple-300 transition">Contacto</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Contáctanos</h3>
            <p className="mb-2">Teléfono: +56 9 2218 0453</p>
            <p className="mb-2">
              Email: <a 
                href="mailto:abogado@pereirayasociados.cl" 
                className="hover:text-purple-300 hover:underline transition"
              >
                abogado@pereirayasociados.cl
              </a>
            </p>
          </div>
        </div>
        <div className="border-t border-purple-700 mt-8 pt-8 text-center">
          <p>&copy; {new Date().getFullYear()} Pereira & Asociados | Abogados. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;