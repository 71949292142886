import React from 'react';
import { Link } from 'react-router-dom';

const AreaCard = ({ title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h3 className="text-xl font-semibold text-purple-800 mb-3">{title}</h3>
    <p className="text-gray-600 mb-4">{description}</p>
    <Link to="/contacto" className="text-purple-600 hover:text-purple-800 transition">
      Consultar &rarr;
    </Link>
  </div>
);

const AreasPractica = () => {
  const areas = [
    {
      title: "Desafuero Maternal",
      description: "Protegemos los derechos de las madres trabajadoras ante intentos de despido durante el período de fuero maternal, asegurando el cumplimiento de las leyes laborales y de protección a la maternidad."
    },
    {
      title: "Discriminación Laboral por Embarazo",
      description: "Abordamos casos de discriminación en el trabajo debido al embarazo o la maternidad, buscando la igualdad de oportunidades y condiciones laborales."
    },
    {
      title: "Permisos y Licencias Parentales",
      description: "Asesoramos sobre los derechos relacionados con permisos pre y post natal, licencias por enfermedad grave del hijo menor de un año, y otros beneficios parentales."
    },
    {
      title: "Reincorporación Laboral Post Natal",
      description: "Ayudamos a madres trabajadoras en su proceso de reincorporación al trabajo, asegurando el respeto a sus derechos y condiciones laborales."
    },
    {
      title: "Acoso Laboral durante el Embarazo",
      description: "Defendemos a trabajadoras que sufren acoso o presiones indebidas en el trabajo debido a su condición de embarazo o maternidad reciente."
    },
    {
      title: "Negociación de Condiciones Laborales",
      description: "Asistimos en la negociación de adaptaciones del puesto de trabajo, horarios flexibles y otras condiciones necesarias durante el embarazo y la lactancia."
    }
  ];

  return (
    <div className="space-y-8">
      <section className="text-center">
        <h1 className="text-4xl font-serif text-purple-800 mb-4">Áreas de Práctica</h1>
        <p className="text-xl text-gray-600">
          En Pereira & Asociados, nos especializamos en proteger los derechos de las madres trabajadoras. 
          Nuestras áreas de práctica se centran en los desafíos legales que enfrentan durante el embarazo y la maternidad.
        </p>
      </section>

      <section className="grid md:grid-cols-2 gap-6">
        {areas.map((area, index) => (
          <AreaCard key={index} title={area.title} description={area.description} />
        ))}
      </section>

      <section className="bg-purple-100 p-8 rounded-lg text-center">
        <h2 className="text-2xl font-serif text-purple-800 mb-4">¿Necesita asesoría legal en alguna de estas áreas?</h2>
        <p className="text-gray-600 mb-6">
          Nuestro equipo de expertos está listo para ayudarle. Contáctenos hoy para una consulta gratuita y confidencial.
        </p>
        <Link 
          to="/contacto" 
          className="bg-purple-600 text-white px-6 py-3 rounded-full text-lg hover:bg-purple-700 transition"
        >
          Solicitar Consulta
        </Link>
      </section>
    </div>
  );
};

export default AreasPractica;