import React, { useState } from 'react';
import { FaWhatsapp, FaBalanceScale, FaHandshake, FaClipboardCheck, FaUserTie } from 'react-icons/fa';
import heroImageDesktop from '../assets/Imagen Header1.png';
import heroImageMobile from '../assets/Imagen Hero - movil.jpg';

const VividIcon = ({ Icon, primaryColor, secondaryColor, tertiaryColor }) => (
  <div className="relative w-10 h-10 sm:w-12 sm:h-12 flex-shrink-0">
    <div className={`absolute inset-0 ${primaryColor} rounded-full`}></div>
    <div className={`absolute inset-0.5 ${secondaryColor} rounded-full flex items-center justify-center`}>
      <Icon className={`text-${tertiaryColor} w-5 h-5 sm:w-6 sm:h-6`} />
    </div>
  </div>
);

const Card = ({ Icon, title, description, onOpen }) => (
  <div 
    className="bg-white p-4 sm:p-5 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 w-full flex flex-col transform hover:-translate-y-1 hover:scale-105" 
    onClick={onOpen}
  >
    <div className="flex items-center mb-3 sm:mb-4">
      {Icon}
      <h3 className="text-base sm:text-lg font-semibold ml-3 leading-tight text-purple-800">{title}</h3>
    </div>
    <p className="text-gray-600 text-sm sm:text-base flex-grow">{description}</p>
  </div>
);

const Modal = ({ isOpen, onClose, title, description }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-4 sm:p-6 rounded-lg max-w-md w-full shadow-2xl">
        <h2 className="text-lg sm:text-xl font-bold mb-3 text-purple-800">{title}</h2>
        <p className="text-gray-600 mb-4 text-sm sm:text-base">{description}</p>
        <button onClick={onClose} className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition-colors duration-300 text-sm">
          Cerrar
        </button>
      </div>
    </div>
  );
};

const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeCard, setActiveCard] = useState(null);

  const handleWhatsAppClick = () => {
    const phoneNumber = "56922180453";
    const message = "Hola, me gustaría solicitar una consulta gratuita.";
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/${phoneNumber}?text=${encodedMessage}`, '_blank');
  };

  const cards = [
    { 
      Icon: <VividIcon Icon={FaBalanceScale} primaryColor="bg-yellow-300" secondaryColor="bg-blue-400" tertiaryColor="white" />,
      title: "Asesoría Legal", 
      description: "Orientación experta en asuntos legales para proteger tus derechos y garantizar el cumplimiento de la ley en todas las situaciones." 
    },
    { 
      Icon: <VividIcon Icon={FaHandshake} primaryColor="bg-green-400" secondaryColor="bg-pink-400" tertiaryColor="white" />,
      title: "Mediación", 
      description: "Facilitamos la resolución de conflictos de manera eficiente y satisfactoria, buscando acuerdos beneficiosos para todas las partes involucradas." 
    },
    { 
      Icon: <VividIcon Icon={FaClipboardCheck} primaryColor="bg-purple-400" secondaryColor="bg-orange-300" tertiaryColor="white" />,
      title: "Representación en Juicio", 
      description: "Defensa sólida y estratégica de tus intereses en procesos judiciales, asegurando la mejor representación legal posible." 
    },
    { 
      Icon: <VividIcon Icon={FaUserTie} primaryColor="bg-red-400" secondaryColor="bg-cyan-300" tertiaryColor="white" />,
      title: "Consultoría Empresarial", 
      description: "Asesoramiento legal integral para el éxito y protección de tu negocio, cubriendo todos los aspectos legales necesarios para su crecimiento." 
    }
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Hero Section */}
      <section className="relative z-0">
        <img src={heroImageMobile} alt="Mujer embarazada" className="md:hidden w-full h-auto" />
        <div className="hidden md:block relative h-[60vh]">
          <img src={heroImageDesktop} alt="Mujer embarazada" className="w-full h-full object-cover object-center" />
        </div>
        
        <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center z-10">
          <div className="container mx-auto px-4">
            <div className="text-white max-w-xl mx-auto md:ml-auto md:mr-0 text-center md:text-right">
              <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">Pereira & Asociados</h1>
              <p className="text-lg md:text-xl lg:text-2xl mb-6">Defiende tu Derecho a la Maternidad</p>
              <button 
                onClick={handleWhatsAppClick}
                className="bg-[#25D366] hover:bg-[#128C7E] text-white px-6 py-3 rounded-full text-lg transition-all duration-300 flex items-center justify-center mx-auto md:ml-auto md:mr-0 shadow-lg hover:shadow-xl"
              >
                <FaWhatsapp className="mr-2" />
                Consulta Gratuita
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Contenido principal */}
      <main className="flex-grow">
        {/* Sección de Cards */}
        <section className="py-12 sm:py-16 px-4">
          <div className="container mx-auto">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-8 sm:mb-10 text-center text-purple-800">Nuestros Servicios</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8 max-w-7xl mx-auto">
              {cards.map((card, index) => (
                <Card
                  key={index}
                  Icon={card.Icon}
                  title={card.title}
                  description={card.description}
                  onOpen={() => {
                    setActiveCard(card);
                    setModalOpen(true);
                  }}
                />
              ))}
            </div>
          </div>
        </section>
      </main>

      {/* Modal */}
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={activeCard?.title}
        description={`${activeCard?.description} Nuestro equipo de expertos está comprometido a brindar soluciones legales efectivas y personalizadas para cada cliente.`}
      />
    </div>
  );
};

export default Home;