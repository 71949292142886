import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/logo.svg';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const navLinkClass = (path) =>
    `block transition duration-150 ease-in-out relative ${
      isActive(path)
        ? 'text-purple-600 font-semibold'
        : 'text-gray-800 hover:text-purple-600'
    }`;

  const activeIndicator = (
    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-purple-600 rounded-full"></span>
  );

  return (
    <header className="bg-white shadow-md relative z-50">
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <div className="w-48 h-12 md:w-64 md:h-16 flex-shrink-0">
              <Logo className="w-full h-full" preserveAspectRatio="xMinYMid meet" />
            </div>
          </Link>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
              aria-label="Toggle menu"
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                {isMenuOpen ? (
                  <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                ) : (
                  <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                )}
              </svg>
            </button>
          </div>
          <nav className={`md:flex ${isMenuOpen ? 'block' : 'hidden'} absolute md:relative top-full left-0 w-full md:w-auto bg-white md:bg-transparent shadow-md md:shadow-none z-50`}>
            <ul className="md:flex py-2 px-4 md:p-0">
              <li className="py-2 md:py-0 md:mr-6">
                <Link to="/" className={navLinkClass('/')} onClick={() => setIsMenuOpen(false)}>
                  Inicio
                  {isActive('/') && activeIndicator}
                </Link>
              </li>
              <li className="py-2 md:py-0 md:mr-6">
                <Link to="/areas-practica" className={navLinkClass('/areas-practica')} onClick={() => setIsMenuOpen(false)}>
                  Áreas de Práctica
                  {isActive('/areas-practica') && activeIndicator}
                </Link>
              </li>
              <li className="py-2 md:py-0">
                <Link to="/contacto" className={navLinkClass('/contacto')} onClick={() => setIsMenuOpen(false)}>
                  Contacto
                  {isActive('/contacto') && activeIndicator}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;